<template>
  <div class="w-full">
    <vs-button
      :disabled="!$atividades.permissoes.includes(2)"
      color="success"
      icon="queue"
      size="small"
      @click="errors.clear(), (modalCadastrarCliente = true)"
    ></vs-button>
    <SideBar
      title="Novo Cliente"
      @hide="modalCadastrarCliente = false"
      :active="modalCadastrarCliente"
      @success="validarCadastro()"
      size="large"
    >
      <div slot="body">
        <div>
          <VuePerfectScrollbar
            class="scroll-cadastro-cliente"
            :settings="settings"
          >
            <div
              class="vs-con-loading__container tab-general w-full"
              id="loadingCadastroCliente"
            >
              <div class="px-3 py-1">
                <vx-card>
                  <vs-divider
                    border-style="dashed"
                    color="success"
                    class="pt-5 my-0"
                    ><span style="font-size: 17px; font-weight: 700"
                      >Cadastro</span
                    ></vs-divider
                  >
                  <vs-row vs-type="flex" class="">
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Nome Fantasia"
                          name="nome"
                          v-validate="'required'"
                          :key="reRender"
                          @change="reRender += 1"
                          v-model="clienteAdd.nome_fantasia"
                          class="inputx w-full px-3"
                        />
                        <vs-row>
                          <span
                            class="ml-3 text-danger text-sm"
                            v-show="errors.has('nome')"
                            >{{ $validators.empty }}</span
                          >
                        </vs-row>
                      </div>
                    </vs-col>
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          id="tipo"
                          v-model="tipo"
                          :options="optionTipo"
                          size="lg"
                          buttons
                          button-variant=""
                          name="tipo"
                          class="pt-10"
                        ></b-form-radio-group>
                      </b-form-group>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-type="flex" class="">
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-input
                        label="Razao Social"
                        name="razao"
                        :key="reRender"
                        @change="reRender += 1"
                        v-model="clienteAdd.razao_social"
                        class="inputx w-full p-3"
                      />
                    </vs-col>
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Cpf / Cnpj"
                          name="cpf_cnpj"
                          v-model="clienteAdd.cpf_cnpj"
                          type="tel"
                          masked="true"
                          :key="reRender"
                          @change="tipo == 1 ? buscarCnpj() : ''"
                          v-mask="
                            tipo == 1 ? '##.###.###/####-##' : '###.###.###-##'
                          "
                          class="inputx w-full px-3"
                        />
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-type="flex">
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-input
                        label="Inscricao Municipal"
                        name="inscricao_municipal"
                        v-model="clienteAdd.inscricao_municipal"
                        class="inputx w-full p-3"
                      />
                    </vs-col>
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-input
                        label="Inscricao Estadual"
                        name="inscricao_estadual"
                        v-model="clienteAdd.inscricao_estadual"
                        class="inputx w-full p-3"
                      />
                    </vs-col>
                  </vs-row>
                  <vs-row vs-type="flex">
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-input
                        label="RG"
                        name="rg"
                        :key="reRender"
                        class="inputx w-full p-3"
                        v-model="clienteAdd.rg"
                      />
                    </vs-col>
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-input
                        label="Data de nascimento"
                        name="data_nascimento"
                        type="date"
                        :key="reRender"
                        @change="reRender += 1"
                        v-model="clienteAdd.data_nascimento"
                        class="inputx w-full p-3"
                      />
                    </vs-col>
                  </vs-row>
                  <vs-row vs-type="flex" class="">
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-input
                        label="Observação"
                        name="obs"
                        v-model="clienteAdd.obs"
                        class="inputx w-full p-3"
                      />
                    </vs-col>
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-checkbox
                        color="success"
                        v-model="clienteAdd.iss_retido"
                        class="pt-6"
                        >ISS RETIDO</vs-checkbox
                      >
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-end"
                      vs-w="6"
                    >
                      <div class="w-full">
                        <label
                          style="color: black; font-size: 0.85rem"
                          class="m-0 pl-3"
                          >Segmento</label
                        ><br />
                        <el-select
                          filterable
                          clearable
                          class="inputx w-full p-3"
                          placeholder="Selecione"
                          v-model="clienteAdd.id_segmento"
                          :popper-append-to-body="false"
                        >
                          <el-option
                            v-for="segmento in segmentos"
                            :value="segmento.id"
                            :label="segmento.nome"
                            :key="segmento.id"
                          ></el-option>
                        </el-select>
                      </div>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-end"
                      vs-w="6"
                    >
                      <div class="w-full">
                        <label
                          style="color: black; font-size: 0.85rem"
                          class="m-0 pl-3"
                          >Colaborador</label
                        ><br />
                        <el-select
                          filterable
                          clearable
                          remote
                          class="inputx w-full p-3"
                          :remote-method="filtroColaborador"
                          placeholder="Pesquise"
                          loading-text="Buscando..."
                          :disabled="!$atividades.permissoes.includes(17)"
                          :loading="loading"
                          v-model="clienteAdd.id_colaborador"
                          :popper-append-to-body="false"
                        >
                          <el-option
                            v-for="colaborador in colaboradores"
                            :value="colaborador.id"
                            :label="colaborador.nome"
                            :key="colaborador.id"
                          ></el-option>
                        </el-select>
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-type="flex" class="">
                    <vs-divider
                      border-style="dashed"
                      color="success"
                      class="pt-5 my-0"
                      ><span style="font-size: 17px; font-weight: 700"
                        >Contatos principais</span
                      ></vs-divider
                    >
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-input
                        label="Email Principal"
                        name="nome"
                        color="success"
                        :key="reRender"
                        @change="reRender += 1"
                        v-model="clienteAdd.email"
                        class="inputx w-full p-3"
                      />
                    </vs-col>
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-input
                        label="Telefone Principal"
                        name="nome"
                        type="tel"
                        v-mask="'(##)#########'"
                        :key="reRender"
                        @change="reRender += 1"
                        color="success"
                        v-model="clienteAdd.telefone"
                        class="inputx w-full p-3"
                      />
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"
import AddEndereco from "./components/AddEndereco.vue"
import moment from "moment"
export default {
  data () {
    return {
      cliente: {},
      clienteAdd: {},
      colaboradores: [],
      loading: false,
      segmentos: [],
      tipo: 1,
      optionTipo: [
        { text: "CPF", value: 0 },
        { text: "CNPJ", value: 1 }
      ],
      optionContato: [
        { text: "E-MAIL", value: 0 },
        { text: "TELEFONE", value: 1 }
      ],
      enderecos: [],
      modalCadastrarCliente: false,
      error: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
        height: 1000
      },
      reRender: 0
    }
  },
  methods: {
    async validarCadastro () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          await this.cadastrarCliente()
        } else {
          this.error = true
          this.$vs.notify(this.$notify.Empty)
        }
      })
    },
    async buscarCnpj () {
      let cpfCnpjValidator = require("cpf-cnpj-validator")
      if (cpfCnpjValidator.cnpj.isValid(this.clienteAdd.cpf_cnpj)) {
        await this.$vs.loading({
          container: "#loadingCadastroCliente",
          scale: 0.6
        })
        try {
          let obj = await this.$receita.receitaWs(this.clienteAdd.cpf_cnpj)
          await this.setDatas(obj)
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        } finally {
          await this.$vs.loading.close(
            "#loadingCadastroCliente > .con-vs-loading"
          )
        }
      }
    },
    async setDatas (obj) {
      if (obj) {
        this.clienteAdd.telefone = await obj.telefone
        this.clienteAdd.nome_fantasia = await obj.fantasia
        this.clienteAdd.razao_social = await obj.nome
        this.clienteAdd.email = await obj.email
        this.reRender += await 1
      }
    },
    async saveEndereco (endereco) {
      this.enderecos.push({
        id_estado: endereco.id_estado,
        id_bairro: endereco.id_bairro,
        id_cidade: endereco.id_cidade,
        id_segmento: endereco.id_segmento,
        logradouro: endereco.logradouro,
        numero: endereco.numero,
        nome: endereco.nome,
        cep: endereco.cep,
        complemento: endereco.complemento,
        lixeira: false
      })
    },
    async cadastrarCliente () {
      await this.$vs.loading({
        container: "#loadingCadastroCliente",
        scale: 0.6
      })
      try {
        if (this.clienteAdd.cpf_cnpj) {
          this.clienteAdd.cpf_cnpj = await this.$removerMascara.remove(this.clienteAdd.cpf_cnpj)
          let cpfCnpjValidator = require("cpf-cnpj-validator")
          if (this.tipo == 0) {
            if (!(cpfCnpjValidator.cpf.isValid(this.clienteAdd.cpf_cnpj.trim()))) {
              this.$vs.notify({
                title: "CPF é inválido",
                text: "Adicione um CPF válido ou NÃO preencha o campo",
                color: "danger"
              })
              return 0
            }
          } else {
            if (!(cpfCnpjValidator.cnpj.isValid(this.clienteAdd.cpf_cnpj.trim()))) {
              this.$vs.notify({
                title: "CNPJ é inválido",
                text: "Adicione um CNPJ válido ou NÃO preencha o campo",
                color: "danger"
              })
              return 0
            }
          }
        }
        this.clienteAdd.id_status = await 1
        this.clienteAdd.tipo = await this.tipo
        this.cliente = await this.$http.post("cliente", this.clienteAdd)
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "cadastrar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Cadastro do cliente N°" + this.cliente.id
        // }
        // await this.$logger(logData)
        this.$vs.notify(this.$notify.Success)
        this.$emit("update")
        this.modalCadastrarCliente = await false
        this.clienteAdd = await {}
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#loadingCadastroCliente > .con-vs-loading"
        )
      }
    },
    async getSegmentos () {
      try {
        this.segmentos = await this.$http.get(`segmento`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getColaboradoresByQuery (query) {
      try {
        this.colaboradores = await this.$http.post(`getColaboradorByQuery`, {
          query: query
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async filtroColaborador (query) {
      if (query.length > 2) {
        this.loading = true
        try {
          await this.getColaboradoresByQuery(query)
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        } finally {
          this.loading = false
        }
      }
    },
  },
  async mounted () {
    await this.getSegmentos()
  },
  components: { ...components, AddEndereco, moment }
};
</script>
<style>
.con-slot-tabs {
  width: 100%;
}
.con-tab-ejemplo {
  width: 100%;
}

.vs-list--item .list-titles .vs-list--subtitle {
  font-size: 1.3rem;
}

.el-tabs__item.is-active {
  color: #ffca00 !important;
}

.el-tabs__item:hover {
  color: #ffca00 !important;
}

.el-tabs__active-bar {
  background-color: #ffca00 !important;
}
.col {
  width: auto;
}
.vs-tabs--content {
  padding: 0;
}
.input-group-prepend {
  color: #ffca00 !important;
}

.bootstrap-button {
  background-color: #ffca00 !important;
  color: black !important;
  cursor: default !important;
}
.scroll-cadastro-cliente {
  height: 80vh !important;
}
</style>
