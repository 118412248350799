<template>
  <vx-card>
    <vs-row
      vs-type="flex"
      class="mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col vs-w="1" vs-justify="center" vs-align="center">
        <h4 style="color: #b4aa99" class="text-center">Clientes.</h4>
      </vs-col>
      <vs-col
        vs-w="3"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
      >
        <ModalCadastroCliente @update="getClientes" />
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="8">
        <vs-input
          color="dark"
          class="mx-1"
          size="small"
          type="text"
          placeholder
          label="Busca"
          v-model="filtro.busca"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          size="small"
          type="text"
          placeholder
          label="RG"
          v-model="filtro.busca_rg"
        ></vs-input>
        <el-select
          filterable
          size="small"
          clearable
          placeholder="Vendedor"
          v-model="filtro.vendedor"
          class="mx-1"
        >
          <el-option
            v-for="vendedor in vendedores"
            :value="vendedor.id"
            :label="vendedor.nome"
            :key="vendedor.id"
          ></el-option>
        </el-select>
        <el-select
          filterable
          size="small"
          clearable
          placeholder="Segmento"
          v-model="filtro.segmento"
          class="mx-1"
        >
          <el-option
            v-for="segmento in segmentos"
            :value="segmento.id"
            :label="segmento.nome"
            :key="segmento.id"
          ></el-option>
        </el-select>
        <el-select
          filterable
          size="small"
          clearable
          placeholder="Estado"
          v-model="filtro.id_estado"
          class="mx-1"
        >
          <el-option
            v-for="estado in estados"
            :value="estado.id"
            :label="estado.nome"
            :key="estado.id"
          ></el-option>
        </el-select>
        <el-select
          filterable
          size="small"
          clearable
          placeholder="Cidade"
          v-model="filtro.id_cidade"
          class="mx-1"
        >
          <el-option
            v-for="cidade in cidades"
            :value="cidade.id"
            :label="cidade.nome"
            :key="cidade.id"
          ></el-option>
        </el-select>
        <el-select
          filterable
          size="small"
          clearable
          placeholder="Situação do Cliente"
          v-model="filtro.situacao"
          class="mx-1"
        >
          <el-option
            v-for="situacao in situacaoCliente"
            :value="situacao.id"
            :label="situacao.nome"
            :key="situacao.id"
          ></el-option>
        </el-select>
        <el-select
          filterable
          size="small"
          clearable
          placeholder="Status"
          v-model="filtro.status"
          class="mx-1"
        >
          <el-option
            v-for="status in statusCliente"
            :value="status.id"
            :label="status.nome"
            :key="status.id"
          ></el-option>
        </el-select>

        <el-button
          size="small"
          type="primary"
          class="ml-1 mr-2"
          plain
          @click="getClientes()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
    <div id="client_table" class="vs-con-loading__container">
      <div class="my-10 py-10" v-if="clientes.length == 0">
        <div class="con-colors my-10 py-10">
          <ul>
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">
                Nenhum cliente
              </h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <!-- <VuePerfectScrollbar class="scroll-clientes" :settings="settings"> -->
        <vs-table
          maxHeight="63vh"
          stripe
          :data="clientes"
          max-items="9"
          pagination
          id="tableCliente"
        >
          <template slot="thead">
            <vs-th sort-key="numero" style="width: 5%">Número</vs-th>
            <vs-th sort-key="nome" style="width: 25%">Nome Fantasia</vs-th>
            <vs-th sort-key="cpf" style="width: 5%">CPF/CNPJ</vs-th>
            <vs-th sort-key="rg" style="width: 5%">RG</vs-th>
            <vs-th sort-key="razao" style="width: 25%">Razao Social</vs-th>
            <vs-th sort-key="colaborador" style="width: 15%">Consultor</vs-th>
            <vs-th sort-key="colaborador" style="width: 15%">Segmento</vs-th>
            <vs-th sort-key="colaborador" style="width: 15%">Situação</vs-th>
            <!-- <vs-th sort-key="colaborador" style="width: 15%">Status</vs-th> -->
            <vs-th>Ações</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="index" v-for="(cliente, index) in data">
              <vs-td :data="cliente.numero" class="text-center font-bold">{{
                cliente.id
              }}</vs-td>
              <vs-td :data="cliente.nome_fantasia">{{
                cliente.nome_fantasia
              }}</vs-td>
              <vs-td :data="cliente.cpf">{{ cliente.cpf_cnpj }}</vs-td>
              <vs-td :data="cliente.rg">{{ cliente.rg }}</vs-td>
              <vs-td :data="cliente.razao_social">{{
                cliente.razao_social
              }}</vs-td>
              <vs-td style="font-size: 12px">{{
                cliente.nomeColaborador != null
                  ? cliente.nomeColaborador
                  : "..."
              }}</vs-td>
              <vs-td class="font-bold">{{ cliente.segmento }}</vs-td>
              <vs-td class="font-semibold">{{
                cliente.status != null ? cliente.status : ""
              }}</vs-td>
<!--               <vs-td class="font-semibold">{{
                cliente.situacao != null ? cliente.situacao : ""
              }}</vs-td> -->
              <vs-td class="actions">
                <ModalEditarCliente
                  @update="getClientes"
                  :cliente="cliente"
                  :botaoEditar="true"
                />
                <modalConfirmarResgate
                  @update="getClientes"
                  v-if="status == 3"
                  :cliente="cliente"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- </VuePerfectScrollbar> -->
        <vx-card class="mt-4" no-shadow card-background="dark">
          <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center">
            <vs-col
              vs-w="6"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
            >
              <span style="color: white; font-size: 14px" class="ml-2"
                >Clientes: {{ clientes.length }}</span
              ></vs-col
            >

            <vs-col
              vs-w="6"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
            >
              <vs-button
                icon="get_app"
                type="line"
                @click="exportToExcel()"
                color="#FFCA00"
                size="small"
                ><b style="font-size: 16px">Excel</b></vs-button
              >
            </vs-col>
          </vs-row>
        </vx-card>
      </div>
    </div>
  </vx-card>
</template>

<script>
import ModalCadastroCliente from "./cadastro.vue";
import ModalEditarCliente from "./editar.vue";
import router from "./../../../router.js";
import components from "@/components/default/exports.js";
import modalConfirmarResgate from "./components/modalConfirmarResgate";

export default {
  data() {
    return {
      clientes: [],
      vendedores: [],
      segmentos: [],
      status: 1,
      filtro: {
        status: 1,
        segmento: null,
        situacao: null,
        vendedor: null,
        busca: null,
        dataHoje: this.$formartData.dataFiltro(),
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
        height: 1000,
      },
      clientesExcel: [],
      statusCliente: [
        { id: 1, nome: "Ativos" },
        { id: 2, nome: "Inativos Sendo Trabalhados" },
        { id: 3, nome: "Inativos" }
      ],
      estados: [],
      cidades: [],      
      situacaoCliente: [
        { id: 1, nome: "Novo" },
        { id: 2, nome: "Com Prospecção" },
        { id: 3, nome: "Aguardando Levantamento" },
        { id: 4, nome: "Levantamento Recebido" },
        { id: 5, nome: "Com Proposta" },
        { id: 6, nome: "Com Contrato" },
        { id: 7, nome: "Para Prospecção" },
        { id: 8, nome: "Resgatado" },
        { id: 9, nome: "Reagendado" },
        { id: 10, nome: "Declinado" }
      ],
    };
  },
  async mounted() {
    await this.getClientes();
    await this.getSegmentos();
    await this.getEstados();
    await this.getCidades();
    await this.getVendedores();
  },
  methods: {
    async getClientes() {
      await this.$vs.loading({ container: "#client_table", scale: 0.6 });
      try {
        const data = await this.$http.post(`cliente_table`, this.filtro);
        this.clientes = data.clientes;
        this.status = data.status;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#client_table > .con-vs-loading");
    },

    async getSegmentos() {
      try {
        this.segmentos = await this.$http.get(`segmento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getDataClientesToExcel() {
      try {
        this.clientesExcel = await this.$http.post(`getDataClientesToExcel`, {
          dataHoje: this.$formartData.dataFiltro(),
        });
        if (this.filtro.status > 0) {
          this.clientesExcel = this.clientesExcel.filter(
            (item) => item.id_status == this.filtro.status
          );
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async exportToExcel() {
      await this.$vs.loading();
      try {
        await this.getDataClientesToExcel();

        import("@/vendor/Export2Excel").then(async (excel) => {
          let headerTitle = [
            "ID",
            "Status",
            "Nome Cliente",
            "Consultor",
            "Segmento",
            "Telefone Principal",
            "Telefone 1",
            "Telefone 2",
            "Telefone 3",
            "Email Principal",
            "Email 1",
            "Email 2",
            "Email 3",
            "ID Contrato Atual",
            "Data Vencimento",
          ];
          let headerVal = [
            "id_cliente",
            "status",
            "nome_fantasia",
            "vendedor",
            "segmento",
            "telefone_principal",
            "telefone_1",
            "telefone_2",
            "telefone_3",
            "email_principal",
            "email_1",
            "email_2",
            "email_3",
            "id_contrato",
            "data_vencimento",
          ];
          const list = this.clientesExcel;
          const data = await this.formatJson(headerVal, list);
          const hoje = await this.$formartData.formatar(new Date());
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: `Clientes_Excel_${hoje}`,
            autoWidth: false,
            bookType: "xlsx",
          });
        });
        this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close();
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    async getVendedores() {
      try {
        this.vendedores = await this.$http.get(`colaborador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getEstados() {
      try {
        this.estados = await this.$http.get(`get_estados_filtro`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getCidades() {
      try {
        this.cidades = await this.$http.get(`getCidadesFiltro`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
  components: {
    ...components,
    ModalCadastroCliente,
    ModalEditarCliente,
    router,
    modalConfirmarResgate,
  },
};
</script>
<style lang="css">
.econtrol {
  color: #ffca00;
}
.col {
  width: auto;
}
.actions {
  width: 60px !important;
}
.scroll-clientes {
  height: 72vh !important;
}
.table_height {
  height: 72vh !important;
}
</style>
