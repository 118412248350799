<template>
  <div class="w-full">
    <div v-if="botaoEditar">
      <EditButton
        :disabled="!$atividades.permissoes.includes(2)"
        @func="openModal()"
      ></EditButton>
    </div>
    <div v-else>
      <vs-col
        vs-w="12"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
      >
        <vs-button
          style="font-weight: 600; font-size: 14px"
          :color="textColor ? textColor : 'dark'"
          class="py-0 px-0 my-0"
          size="small"
          type="line"
          @click="openModal()"
        >
          {{ cliente.nome_fantasia ? cliente.nome_fantasia : "Carregando..." }}
        </vs-button>
      </vs-col>
    </div>
    <SideBar
      title
      @hide="cancelar"
      :active="modalEditarCliente"
      :item="cliente"
      icon="edit"
      :size="size"
      :disabled="
        !$atividades.permissoes.includes(3) &&
        !$atividades.permissoes.includes(6) &&
        !$atividades.permissoes.includes(9) &&
        !$atividades.permissoes.includes(15)
      "
      textSucces="Atualizar"
      @excluir="excluirCliente"
      @status="mudarStatusCliente"
      @success="editar()"
    >
      <div slot="body" :key="reRender">
        <div
          class="w-full vs-con-loading__container"
          :id="'clienteEditar' + cliente.id"
        >
          <vs-tabs
            position="top"
            color="dark"
            alignment="center"
            id="profile-tabs"
          >
            <vs-tab
              icon-pack="feather"
              icon="icon-user"
              label="Cliente"
              class
              @click="size = 'large'"
            >
              <VuePerfectScrollbar
                class="scroll-cliente-editar"
                :settings="settings"
              >
                <div class="tab-general w-full">
                  <div class="px-3 py-3">
                    <vx-card>
                      <vs-divider
                        border-style="dashed"
                        color="success"
                        class="pt-5 my-0"
                      >
                        <span style="font-size: 17px; font-weight: 700"
                          >Editar Cliente {{ cliente.id }}</span
                        >
                      </vs-divider>
                      <vs-row
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                      >
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full">
                            <vs-input
                              label="Nome Fantasia"
                              name="nome"
                              v-model="cliente.nome_fantasia"
                              v-validate="'required'"
                              class="inputx w-full px-3"
                            />
                            <vs-row>
                              <span
                                class="ml-3 text-danger text-sm"
                                v-show="errors.has('nome')"
                                >{{ $validators.empty }}</span
                              >
                            </vs-row>
                          </div>
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <b-form-group>
                            <b-form-radio-group
                              id="tipo"
                              v-model="cliente.tipo_pessoa"
                              :options="optionTipo"
                              size="lg"
                              buttons
                              button-variant
                              name="tipo"
                              class="pt-10"
                            ></b-form-radio-group>
                          </b-form-group>
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Razao Social"
                            name="razao"
                            v-model="cliente.razao_social"
                            class="inputx w-full p-3"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full">
                            <vs-input
                              label="Cpf / Cnpj"
                              name="cpf_cnpj"
                              v-model="cliente.cpf_cnpj"
                              type="tel"
                              masked="true"
                              @change="
                                cliente.tipo_pessoa == 2 ? buscarCnpj() : ''
                              "
                              v-mask="
                                cliente.tipo_pessoa == 2
                                  ? '##.###.###/####-##'
                                  : '###.###.###-##'
                              "
                              class="inputx w-full px-3"
                            />
                          </div>
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Inscricao Municipal"
                            name="inscricao_municipal"
                            v-model="cliente.inscricao_municipal"
                            class="inputx w-full p-3"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Inscricao Estadual"
                            name="inscricao_estadual"
                            v-model="cliente.inscricao_estadual"
                            class="inputx w-full p-3"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="RG"
                            name="rg"
                            v-model="cliente.rg"
                            class="inputx w-full p-3"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Data de nascimento"
                            name="data_nascimento"
                            type="date"
                            v-model="cliente.data_nascimento"
                            class="inputx w-full p-3"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Observação"
                            name="obs"
                            v-model="cliente.obs"
                            class="inputx w-full p-3"
                          />
                        </vs-col>
                        <vs-col
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-end"
                          vs-w="6"
                        >
                          <div class="w-full">
                            <label
                              style="color: black; font-size: 0.85rem"
                              class="m-0 pl-3"
                              >Segmento</label
                            >
                            <br />
                            <el-select
                              filterable
                              clearable
                              class="inputx w-full p-3"
                              placeholder="Selecione"
                              v-model="cliente.id_segmento"
                              :popper-append-to-body="false"
                            >
                              <el-option
                                v-for="segmento in segmentos"
                                :value="segmento.id"
                                :label="segmento.nome"
                                :key="segmento.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vs-col>
                        <vs-col
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="flex-end"
                          vs-w="6"
                        >
                          <div class="w-full">
                            <label
                              style="color: black; font-size: 0.85rem"
                              class="m-0 pl-3"
                              >Colaborador</label
                            >
                            <br />
                            <el-select
                              filterable
                              clearable
                              remote
                              class="inputx w-full p-3"
                              :remote-method="filtroColaborador"
                              placeholder="Pesquise"
                              loading-text="Buscando..."
                              :disabled="!$atividades.permissoes.includes(17)"
                              :loading="loading"
                              v-model="cliente.id_colaborador"
                              :popper-append-to-body="false"
                            >
                              <el-option
                                v-for="colaborador in colaboradores"
                                :value="colaborador.id"
                                :label="colaborador.nome"
                                :key="colaborador.id"
                              ></el-option>
                            </el-select>
                          </div>
                        </vs-col>

                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <div class="w-full">
                            <vs-divider class="mb-2">Preferências</vs-divider>
                            <vs-row>
                              <vs-col
                                vs-w="3"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <vs-checkbox
                                  class="inputx w-full px-3 py-2"
                                  color="success"
                                  v-model="cliente.iss_retido"
                                  >ISS RETIDO</vs-checkbox
                                >
                              </vs-col>
                              <vs-col
                                vs-w="6"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <vs-checkbox
                                  class="inputx w-full px-3 py-2"
                                  color="success"
                                  v-model="cliente.relatorio_indicativo"
                                  >RELATÓRIO INDICATIVO</vs-checkbox
                                >
                              </vs-col>
                            </vs-row>
                          </div>
                        </vs-col>
                      </vs-row>

                      <vs-row vs-type="flex" class>
                        <vs-divider
                          border-style="dashed"
                          color="success"
                          class="pt-5 my-0"
                        >
                          <span style="font-size: 17px; font-weight: 700"
                            >Contatos principais</span
                          >
                        </vs-divider>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Email Principal"
                            name="nome"
                            color="success"
                            v-model="cliente.email"
                            class="inputx w-full p-3"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            label="Telefone Principal"
                            name="nome"
                            type="tel"
                            v-mask="'(##)#########'"
                            color="success"
                            v-model="cliente.telefone"
                            class="inputx w-full p-3"
                          />
                        </vs-col>
                        <vs-col
                          vs-w="6"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-button
                            style="font-weight: 600; font-size: 14px"
                            :color="textColor ? textColor : 'dark'"
                            class="my-2 mr-2"
                            size="small"
                            @click="invitePortal()"
                          >
                            Enviar convite ao portal de cliente
                          </vs-button>
                        </vs-col>
                      </vs-row>
                    </vx-card>
                  </div>
                </div>
              </VuePerfectScrollbar>
            </vs-tab>
            <vs-tab
              icon-pack="feather"
              icon="icon-phone"
              label="Contatos"
              @click="size = 'large'"
            >
              <div class="tab-general w-full">
                <div class="px-3 py-3">
                  <vx-card>
                    <vs-divider
                      border-style="dashed"
                      color="success"
                      class="pt-5 my-0"
                    >
                      <span style="font-size: 17px; font-weight: 700"
                        >Contatos Adicionais</span
                      >
                    </vs-divider>
                    <vs-row vs-type="flex" class="w-full">
                      <vs-col vs-w="12">
                        <AddContato
                          :cliente="cliente"
                          class="pb-2"
                        ></AddContato>
                      </vs-col>
                    </vs-row>
                  </vx-card>
                </div>
              </div>
            </vs-tab>
            <vs-tab
              icon-pack="feather"
              icon="icon-map-pin"
              label="Enderecos"
              @click="size = 'huge'"
            >
              <div class="tab-general w-full">
                <div class="px-3 py-3">
                  <vx-card>
                    <vs-divider
                      border-style="dashed"
                      color="success"
                      class="pt-5 my-0"
                    >
                      <span style="font-size: 17px; font-weight: 700"
                        >Enderecos</span
                      >
                    </vs-divider>
                    <vs-row vs-type="flex" class="w-full">
                      <vs-col vs-w="12">
                        <EditEndereco
                          :blockLenvNovoModal="true"
                          size="large"
                          @editEndereco="saveEndereco"
                          :cliente="cliente"
                        ></EditEndereco>
                      </vs-col>
                    </vs-row>
                  </vx-card>
                </div>
              </div>
            </vs-tab>
            <vs-tab
              icon-pack="feather"
              icon="icon-book"
              label="Documentos"
              @click="size = 'large'"
            >
              <div class="tab-general w-full">
                <div class="px-3 py-3">
                  <div class="w-full">
                    <vs-divider
                      border-style="dashed"
                      color="success"
                      class="pt-5 my-0"
                    >
                      <span style="font-size: 17px; font-weight: 700"
                        >Documentos</span
                      >
                    </vs-divider>
                    <vs-row vs-type="flex" class="w-full">
                      <vs-col vs-w="12">
                        <Documentos
                          @close="closeModal()"
                          :item="cliente"
                          class="pb-2"
                        ></Documentos>
                      </vs-col>
                    </vs-row>
                  </div>
                </div>
              </div>
            </vs-tab>
            <vs-tab
              icon-pack="feather"
              icon="icon-paperclip"
              label="Historico"
              @click="size = 'huger'"
            >
              <div class="tab-general w-full">
                <div class="px-3 py-3">
                  <vx-card>
                    <vs-divider
                      border-style="dashed"
                      color="success"
                      class="pt-5 my-0"
                    >
                      <span style="font-size: 17px; font-weight: 700"
                        >Historico</span
                      >
                    </vs-divider>
                    <vs-row vs-type="flex" class="w-full">
                      <vs-col vs-w="12" class="pt-5">
                        <Assunto :cliente="cliente"></Assunto>
                      </vs-col>
                    </vs-row>
                  </vx-card>
                </div>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
import EditEndereco from "./components/EditEndereco.vue";
import Documentos from "./components/documentos.vue";
import AddContato from "./components/AddContato.vue";
import Assunto from "./components/assuntoCliente.vue";
export default {
  props: {
    cliente: {
      required: true,
    },
    botaoEditar: false,
    textColor: null,
  },
  data() {
    return {
      size: "large",
      optionTipo: [
        { text: "CPF", value: 1 },
        { text: "CNPJ", value: 2 },
      ],
      optionContato: [
        { text: "E-MAIL", value: 0 },
        { text: "TELEFONE", value: 1 },
      ],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000,
      },
      enderecos: [],
      endereco: {},
      modalEditarCliente: false,
      originalData: {},
      error: false,
      reRender: 0,
      loading: false,
      colaboradores: [],
      segmentos: [],
    };
  },
  methods: {
    async openModal() {
      this.errors.clear();
      this._beforeEditingCache = Object.assign({}, this.cliente);
      this.originalData = this.cliente;
      this.modalEditarCliente = true;
      if (this.cliente.id_colaborador) {
        this.getColaboradoresByQuery(this.cliente.id_colaborador);
      }
      await this.getSegmentos();
    },
    async closeModal() {
      this.modalEditarCliente = false;
    },
    async editar() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          await this.editarCliente();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async buscarCnpj() {
      let cpfCnpjValidator = require("cpf-cnpj-validator");
      if (cpfCnpjValidator.cnpj.isValid(this.cliente.cpf_cnpj)) {
        await this.$vs.loading({
          container: "#clienteEditar" + this.cliente.id,
          scale: 0.6,
        });
        try {
          let obj = await this.$receita.receitaWs(this.cliente.cpf_cnpj);
          await this.setDatas(obj);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          await this.$vs.loading.close(
            "#clienteEditar" + this.cliente.id + "> .con-vs-loading"
          );
        }
      }
    },
    async setDatas(obj) {
      if (obj) {
        this.cliente.telefone = await obj.telefone;
        this.cliente.nome_fantasia = (await obj.fantasia)
          ? obj.fantasia
          : this.cliente.nome_fantasia;
        this.cliente.razao_social = (await obj.nome)
          ? obj.nome
          : this.cliente.razao_social;
        this.cliente.email = await obj.email;
        this.reRender += await 1;
      }
    },
    async saveEndereco(endereco) {
      this.enderecos.push({
        id_estado: endereco.id_estado,
        id_bairro: endereco.id_bairro,
        id_cidade: endereco.id_cidade,
        id_segmento: endereco.id_segmento,
        logradouro: endereco.logradouro,
        numero: endereco.numero,
        nome: endereco.nome,
        cep: endereco.cep,
        complemento: endereco.complemento,
        lixeira: false,
      });
      this.endereco = endereco;
    },
    async editarCliente() {
      await this.$vs.loading({
        container: "#clienteEditar" + this.cliente.id,
        scale: 0.6,
      });
      try {
        if (this.cliente.cpf_cnpj) {
          this.cliente.cpf_cnpj = await this.$removerMascara.remove(
            this.cliente.cpf_cnpj
          );
          let cpfCnpjValidator = require("cpf-cnpj-validator");
          if (this.cliente.tipo_pessoa == 1) {
            if (!cpfCnpjValidator.cpf.isValid(this.cliente.cpf_cnpj.trim())) {
              this.$vs.notify({
                title: "CPF é inválido",
                text: "Adicione um CPF válido ou NÃO preencha o campo",
                color: "danger",
              });
              return 0;
            }
          } else {
            if (!cpfCnpjValidator.cnpj.isValid(this.cliente.cpf_cnpj.trim())) {
              this.$vs.notify({
                title: "CNPJ é inválido",
                text: "Adicione um CNPJ válido ou NÃO preencha o campo",
                color: "danger",
              });
              return 0;
            }
          }
        }
        const res = await this.$http.put(
          "cliente/" + this.cliente.id,
          this.cliente
        );
        this.$vs.notify(this.$notify.Success);
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "atualizar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Edição do cliente N°" + res.id,
        // };
        // await this.$logger(logData);
        await this.$emit("update");
        this.modalEditarCliente = await false;
      } catch (err) {
        this.$vs.loading.close();
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#clienteEditar" + this.cliente.id + " > .con-vs-loading"
        );
      }
    },
    async invitePortal() {
      try {
        await this.$http.post(`invite_portal`, { client: this.cliente, franquia: process.env.VUE_APP_FRANCHISING });
        this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async excluirCliente() {
      await this.$vs.loading({
        container: "#clienteEditar" + this.cliente.id,
        scale: 0.6,
      });
      try {
        await this.$http.delete(`cliente/${this.cliente.id}`);
        this.$vs.notify(this.$notify.Success);        
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        await this.$vs.loading.close(
          "#clienteEditar" + this.cliente.id + " > .con-vs-loading"
        );
        this.modalEditarCliente = await false;
      }
    },
    async mudarStatusCliente() {
      
      await this.$vs.loading({
        container: "#clienteEditar" + this.cliente.id,
        scale: 0.6,
      });
      try {
        const res = await this.$http.put(`mudarStatusCliente`, {
          id: this.cliente.id,
          lixeira: !this.cliente.lixeira
        });
        this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        await this.$vs.loading.close(
          "#clienteEditar" + this.cliente.id + " > .con-vs-loading"
        );
        this.modalEditarCliente = await false;
      }
    },
    async getSegmentos() {
      try {
        this.segmentos = await this.$http.get(`segmento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getColaboradoresByQuery(query) {
      try {
        this.colaboradores = await this.$http.post(`getColaboradorByQuery`, {
          query: query,
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroColaborador(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getColaboradoresByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async cancelar() {
      Object.assign(this.cliente, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarCliente = false;
    },
  },
  components: { ...components, EditEndereco, AddContato, Assunto, Documentos },
};
</script>
<style lang="scss" scoped>
.con-slot-tabs {
  width: 100%;
}
.con-tab-ejemplo {
  width: 100%;
}

.vs-list--item .list-titles .vs-list--subtitle {
  font-size: 1.3rem;
}

.el-tabs__item.is-active {
  color: #ffca00 !important;
}

.el-tabs__item:hover {
  color: #ffca00 !important;
}

.el-tabs__active-bar {
  background-color: #ffca00 !important;
}
.col {
  width: auto;
}
.vs-tabs--content {
  padding: 0;
}
.input-group-prepend {
  color: #ffca00 !important;
}

.bootstrap-button {
  background-color: #ffca00 !important;
  color: black !important;
  cursor: default !important;
}
.scroll-cliente-editar {
  height: 72vh !important;
}
</style>
