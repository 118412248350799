<template>
  <form>
    <VuePerfectScrollbar class="scroll-add-endereco" :settings="settings">
      <div class="px-2">
        <vx-card
          no-radius
          no-shadow
          title="Cadastro"
          class="collapsed"
          card-background=""
          collapseAction
          data-action="collapse"
        >
          <div id="container" class="vs-con-loading__container">
            <vs-row vs-type="flex" class="">
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <vs-input
                  class="w-full p-3"
                  label="Nome"
                  color="dark"
                  name="nome"
                  v-model="endereco.nome"
                />
              </vs-col>
              <vs-col
                vs-w="4"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <div style="display:inline-block" class=" w-full">
                  <vs-input
                    color="dark"
                    class="w-full  px-3"
                    label="CEP"
                    v-validate="'required'"
                    name="cep"
                    @change="getCepInfo($event)"
                    v-model="endereco.cep"
                  />
                  <span
                    class="ml-3 text-danger text-sm"
                    v-show="errors.has('cep')"
                  >
                    {{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
              <vs-col
                vs-w="2"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <div style="display:inline-block" class=" w-full">
                  <vs-input
                    color="dark"
                    class="w-full px-3"
                    v-validate="'required'"
                    label="numero"
                    name="numero"
                    v-model="endereco.numero"
                  />
                  <span
                    class="ml-3 text-danger text-sm"
                    v-show="errors.has('numero')"
                  >
                    {{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
            </vs-row>
            <vs-row vs-type="flex" class="">
              <vs-col
                vs-w="4"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <div style="display:inline-block;margin-right:20px;">
                  <label style="color: black; font-size: 13px;">Estado</label
                  ><br />
                  <el-select
                    filterable
                    clearable
                    class="select-info"
                    v-validate="'required'"
                    @change="getCidade($event)"
                    placeholder="Selecione..."
                    name="id_estado"
                    is-selected.sync="true"
                    v-model="endereco.id_estado"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="estado in estados"
                      class="select-info"
                      :value="estado.id"
                      :label="estado.nome"
                      :key="estado.id"
                    >
                    </el-option>
                  </el-select>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('id_estado')"
                  >
                    {{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
              <vs-col
                vs-w="4"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <div style="display:inline-block;margin-right:20px;">
                  <label style="color: black; font-size: 13px;">Cidade</label
                  ><br />
                  <el-select
                    filterable
                    clearable
                    class="select-info"
                    :disabled="!endereco.id_estado"
                    v-validate="'required'"
                    @change="getBairro($event)"
                    placeholder="Selecione..."
                    name="id_cidade"
                    v-model="endereco.id_cidade"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="cidade in cidades"
                      class="select-info"
                      :value="cidade.id"
                      :label="cidade.nome"
                      :key="cidade.id"
                    >
                    </el-option>
                  </el-select>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('id_cidade')"
                  >
                    {{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
              <vs-col
                vs-w="4"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <div style="display:inline-block;margin-right:20px;">
                  <label style="color: black; font-size: 13px;">Bairro</label
                  ><br />
                  <el-select
                    filterable
                    clearable
                    class="select-info"
                    v-validate="'required'"
                    :disabled="!endereco.id_cidade || !endereco.id_estado"
                    placeholder="Selecione..."
                    name="bairro"
                    v-model="endereco.id_bairro"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="bairro in bairros"
                      class="select-info"
                      :value="bairro.id"
                      :label="bairro.nome"
                      :key="bairro.id"
                    >
                    </el-option>
                  </el-select>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('bairro')"
                  >
                    {{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
            </vs-row>
            <vs-row vs-type="flex" class="">
              <vs-col
                vs-w="5"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <div style="display:inline-block" class=" w-full">
                  <vs-input
                    class="w-full p-3"
                    v-validate="'required'"
                    color="dark"
                    label="Logradouro"
                    name="logradouro"
                    v-model="endereco.logradouro"
                  />
                  <span
                    class="ml-3 text-danger text-sm"
                    v-show="errors.has('logradouro')"
                  >
                    {{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
              <vs-col
                vs-w="4"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <vs-input
                  class="w-full p-3"
                  label="Complemento"
                  color="dark"
                  name="complemento"
                  v-model="endereco.complemento"
                />
              </vs-col>
              <vs-col
                vs-w="3"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <div style="display:inline-block;margin-right:20px;">
                  <label style="color: black; font-size: 13px;">Segmento</label
                  ><br />
                  <el-select
                    filterable
                    clearable
                    class="select-info"
                    v-validate="'required'"
                    placeholder="Selecione..."
                    name="segmento"
                    v-model="endereco.id_segmento"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="segmento in segmentos"
                      class="select-info"
                      :value="segmento.id"
                      :label="segmento.nome"
                      :key="segmento.id"
                    >
                    </el-option>
                  </el-select>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('segmento')"
                  >
                    {{ $validators.empty }}</span
                  >
                </div>
              </vs-col>
            </vs-row>
            <vs-button
              class=" w-full"
              color="success"
              type="relief"
              @click="submitForm()"
              >Adicionar</vs-button
            >
          </div>
        </vx-card>
      </div>
      <vs-divider
        color="dark"
        class="py-1 my-0"
        v-if="enderecos.length > 0"
      ></vs-divider>
      <div class="px-2">
        <vx-card
          no-radius
          no-shadow
          :title="'Ver ' + enderecos.length + ' endereco(s)'"
          card-background=""
          v-if="enderecos.length > 0"
          collapseAction
        >
          <div class="vx-row" v-if="enderecos.length > 0">
            <div class="w-full">
              <vs-list>
                <vs-list-item
                  icon="place"
                  :key="indextr"
                  v-for="(tr, indextr) in enderecos"
                  :title="
                    tr.nome +
                      ' - ' +
                      tr.nomeSegmento +
                      ' - ' +
                      tr.nomeBairro +
                      ' ' +
                      tr.logradouro +
                      ' Nº ' +
                      tr.numero
                  "
                >
                  <vs-button size="small" color="danger" type="relief"
                    >Excluir</vs-button
                  >
                </vs-list-item>
              </vs-list>
            </div>
          </div>
        </vx-card>
      </div>
    </VuePerfectScrollbar>
  </form>
</template>

<script>
import components from "@/components/default/exports.js";
export default {
  data() {
    return {
      endereco: {
        logradouro: "",
        complemento: "",
        id_estado: 0,
        id_cidade: 0,
        id_bairro: 0,
        id_segmento: 0,
        nome: "",
        numero: "",
        cep: ""
      },
      enderecos: [],
      cidades: [],
      estados: [],
      bairros: [],
      segmentos: [],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000
      }
    };
  },
  methods: {
    async addEndereco() {
      let sendback;
      await this.$vs.loading({ container: "#container", scale: 0.6 });
      try {
        this.enderecos.push({
          id_estado: this.endereco.id_estado,
          id_bairro: this.endereco.id_bairro,
          id_cidade: this.endereco.id_cidade,
          id_segmento: this.endereco.id_segmento,
          logradouro: this.endereco.logradouro,
          numero: this.endereco.numero,
          nome: this.endereco.nome,
          cep: this.endereco.cep,
          complemento: this.endereco.complemento,
          lixeira: false,
          nomeEstado: await this.estados.find(
            x => x.id == this.endereco.id_estado
          ).nome,
          nomeCidade: await this.cidades.find(
            x => x.id == this.endereco.id_cidade
          ).nome,
          nomeBairro: await this.bairros.find(
            x => x.id == this.endereco.id_bairro
          ).nome,
          nomeSegmento: await this.segmentos.find(
            x => x.id == this.endereco.id_segmento
          ).nome
        });
        sendback = this.endereco;
        this.endereco = await {
          id_bairro: null,
          id_cidade: null,
          id_estado: null,
          id_segmento: null
        };
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("addEndereco", sendback);
        await this.$vs.loading.close("#container > .con-vs-loading");
      }
    },
    async getCidade(estado) {
      await this.$vs.loading({ container: "#container", scale: 0.6 });
      try {
        this.cidades = await this.$http.get("cidade/" + estado);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#container > .con-vs-loading");
      }
    },
    async getBairro(cidade) {
      await this.$vs.loading({ container: "#container", scale: 0.6 });
      try {
        this.bairros = await this.$http.get("bairro/" + cidade);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#container > .con-vs-loading");
      }
    },
    async getEstado() {
      await this.$vs.loading({ container: "#container", scale: 0.6 });
      try {
        this.estados = await this.$http.get("estado");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#container > .con-vs-loading");
      }
    },
    async getSegmentos() {
      await this.$vs.loading({ container: "#container", scale: 0.6 });
      try {
        this.segmentos = await this.$http.get("segmento");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#container > .con-vs-loading");
      }
    },
    async getCepInfo($event) {
      await this.$vs.loading({ container: "#container", scale: 0.6 });
      try {
        const res = await this.$http.post(`cep`, { cep: $event.target.value });
        this.endereco.logradouro = await res.cep.street;
        this.endereco.id_estado = await res.estado.id;
        this.endereco.id_cidade = await res.cidade.id;
        this.endereco.id_bairro = await res.bairro.id;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#container > .con-vs-loading");
      }
    },
    async submitForm() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            // this.addEndereco()
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
            reject("error");
          }
        });
      });
    }
  },
  async mounted() {
    await this.getEstado();
    await this.getSegmentos();
  },
  components: { ...components }
};
</script>
<style lang="scss">
.scroll-add-endereco {
  height: 55vh;
}
.vx-card__title h4 {
  font-weight: 600;
  text-transform: uppercase !important;
}
.feather-chevron-up {
  width: 30px !important;
  height: 30px !important;
}
</style>
