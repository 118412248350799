<template>
  <div>
    <vs-button
      color="success"
      size="small"
      icon="person_add"
      type="relief"
      :disabled="!$atividades.permissoes.includes(2)"
      @click="showPopup = true"
    ></vs-button>
    <vs-popup
      :title="'Resgatar do cliente ' + cliente.nome_fantasia"
      :active.sync="showPopup"
    >
      <!-- <vs-row  vs-w="12" vs-type="flex" vs-align="center">
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <h5>Confirmar resgate do cliente <b>{{cliente.nome_fantasia}}?</b></h5>
        </vs-col>
      </vs-row> -->
      <vs-row vs-type="flex" vs-align="center" vs-justify="space-around">
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vx-card class="confirmar overflow-hidden" @click="resgatarCliente()">
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="p-5"
                  >
                    <span>
                      <vs-icon
                        icon="person_add"
                        size="60px"
                        color="success"
                      ></vs-icon>
                    </span>
                  </vs-col>
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="pb-5"
                  >
                    <span style="font-size: 20px">CONFIRMAR RESGATE</span>
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>

        <!-- <vs-col vs-w="5" vs-type="flex" vs-justify="center" vs-align="center">
          <vx-card class="cancelar overflow-hidden" @click="showPopup = false">
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="p-5"
                  >
                    <span>
                      <vs-icon
                        icon="person_add_disabled"
                        size="60px"
                        color="danger"
                      ></vs-icon>
                    </span>
                  </vs-col>
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="pb-5"
                  >
                    <span style="font-size: 20px">CANCELAR</span>
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col> -->
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    cliente: { require: true },
  },
  data () {
    return {
      showPopup: false,
    }
  },
  methods: {
    async resgatarCliente () {
      this.$vs.loading()
      try {
        const res = await this.$http.put(`cliente/` + this.cliente.id, {
          id_status: 8,
          id_colaborador: localStorage.getItem("id"),
        })
        await this.logAssunto(res)
        await this.$emit("update")
        this.showPopup = false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    },
    async logAssunto (res) {
      let logData = {
        id_colaborador: localStorage.getItem("id"),
        funcao: "atualizar",
        setor: "comercial",
        ip: window.localStorage.getItem("ip"),
        texto: "Resgate do Cliente N°" + res.id,
      }
      await this.$logger(logData)
      await this.$http.post("assuntoCliente", {
        id_assuntos: 1,
        id_cliente: this.cliente.id,
        id_estagio: 1,
        status: 1,
        tipo: this.cliente.id_tipo_contato,
        id_colaborador: window.localStorage.getItem("id"),
        obs: "Resgate do Cliente N°" + res.id,
      })
    },
  },
  components: { ...components },
};
</script>
<style lang="scss" scoped>
.confirmar {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.cancelar {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #c0564b;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
